
import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import UserServices from '@/services/user-services';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [WelcomeMixin]
})
export default class Login extends Vue {
    valid = false;
    resetCode!: string;
    confirmPassword = '';
    password = '';
    loading!: boolean;
    requiredRule!: Array<any>;
    emailRules!: Array<any>;
    hasErrorMsg!: boolean;
    errorMsg!: string;

    get confirmPasswordRules() {
      return this.requiredRule.concat([
        (v: string) => this.password === v || this.$t('notification12')
      ]);
    }

    $refs!: {
      confirm: Confirm,
      resetForm: Vue & { validate: () => boolean, resetValidation: () => void }
    }

    resetPassword() {
      if (this.$refs.resetForm.validate()) {
        this.loading = true;
        UserServices.resetPassword({ token: this.resetCode, password: this.password, confirm: this.confirmPassword }, true).then(async (resp) => {
          const data = resp ? resp.data : { data: { systemDown: 'true' } };
          if (data.systemDown === 'true') {
            this.errorMsg = this.$t('notification06') as string;
          } else if (data.error === 'true') {
            this.errorMsg = this.$t('notification04') as string;
          } else if (!resp.data.success) {
            this.errorMsg = this.$t('resetPasswordMsg') as string;
          } else {
            this.resetCode = '';
            return this.$router.replace({ path: '/plans', query: { token: resp.data.userData.accessToken } });
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    }
}
